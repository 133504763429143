import module from 'module';

const forever = 5 * 24 * 60 * 60 * 1000;
const twelveHours = 12 * 60 * 60 * 1000;
const oneHour = 60 * 60 * 1000;
const oneMinute = 60 * 1000;
const none = 0;
const defaultCache = none;

const apiHost = __API_URL;
module.constant('config', Object.freeze({
    apiPrefix: `${apiHost}/api/v2`,
    devProfile: window.nxDevMode,
    internetCheckInterval: disabledOnDev(30 * 1000),
    postingDateValidationInterval: 65 * 1000,
    cashReceiptThreshold: 500000,
    appVersionCheckInterval: oneMinute,
    cache: {

      // Forever (which is aprox. a week)
      printBackgroundCache: forever,
      translation: forever,
      permissionCache: forever,

      // 12 hours
      dict: twelveHours,
      misGroups: twelveHours,
      loanPurposesToIndustry: twelveHours,
      productMappingsAccounts: twelveHours,
      productStatusMigration: twelveHours,
      propertyConfig: twelveHours,
      product: twelveHours,
      pawn: twelveHours,
      pawnItemTypes: twelveHours,
      pawnMetalTypesConfig: twelveHours,
      pawnItemStoneColourConfig: twelveHours,
      pawnItemStoneCutGradeConfig: twelveHours,
      pawnItemStoneRateConfig: twelveHours,
      pawnItemStoneTypeConfig: twelveHours,
      pawnItemAttributeTypeConfig: twelveHours,
      pawnItemDefectTypeConfig: twelveHours,
      organizationsConfig: twelveHours,
      rolesConfig: twelveHours,
      ledgerActionTemplate: twelveHours,
      systemProperties: twelveHours,
      currency: twelveHours,
      actionCategory: twelveHours,
      depositoryAccount: twelveHours,
      prints: twelveHours,
      passbook: twelveHours,
      certificate: twelveHours,
      casaTypes: twelveHours,
      ata: twelveHours,
      commandMetadata: twelveHours,
      forex: twelveHours,
      schemaTables: twelveHours,
      pawnTag: twelveHours,
      productItemsLastSnapshot: twelveHours,

      // 1 hour
      singleUser: oneHour,
      users: oneHour,
      commandRoleMapCache: oneHour,

      // 1 minute
      termDepositTypes: oneMinute,
      loanProducts: oneMinute,
      accountProducts: oneMinute,
      pawnProducts: oneMinute,
      layawayProducts: oneMinute,
      depositAccountTypes: oneMinute,
      branches: oneMinute,
      productFees: oneMinute,
      pawnMetalRates: oneMinute,
      pawnAuctionConfig: oneMinute,
      pawnItem: oneMinute,
      ledgerTag: oneMinute,
      branchWorkingDay: oneMinute,
      forexDocumentTypes: oneMinute,
      forexDocumentRates: oneMinute,
      forexDocumentTypeRates: oneMinute,

      // Default
      productMappings: defaultCache,
      ledger: defaultCache,
      productDefinitions: defaultCache,
      workingDays: defaultCache,
      checkbook: defaultCache,
      productLock: defaultCache,

      // None
      fileCache: none,  // don't turn this on, it's broken! (TODO)

      // Conditional
      userCounter: disabledOnDev(50000)
    },
    // pagination config for at-table
    paginationConfig: {
      itemsPerPage: 10,
      maxPages: 9,
      fillLastPage: false
    },
    defaultLanguage: 'en',
    statusRefreshInterval: 60000,
    actionCounterFetchInterval: disabledOnDev(60000),
    batchProcessStatusFetchInterval: 10000,
    expirationTime: 30, //how many DAYS must pass to expire user
    googleAnalyticsAccount: 'UA-120636974-1'
  })
);

function disabledOnDev(ms) {
  return window.nxDevMode ? 99999999999999999 : ms;
}
