import module from 'module';
import _ from 'lodash';
import 'rxjs/add/operator/combineLatest';
import BigNumber from 'bignumber.js';
import './new-pawn-parameters.style.less';

const templateUrl = require('./new-pawn-parameters.template.html');

class CustomerPawnCreateParameters {

  constructor($scope, authentication, userCache, rolesCache, newPawnService, popup, pawnTagCache, pawnAuctionCache) {
    this.$scope = $scope;
    this.authentication = authentication;
    this.userCache = userCache;
    this.rolesCache = rolesCache;
    this.newPawnService = newPawnService;
    this.popup = popup;
    this.pawnTagCache = pawnTagCache;
    this.pawnAuctionCache = pawnAuctionCache;
  }

  async $onInit() {
    this.calculationNeedsUpdate = false;
    this.calculationInProgress = false;
    this.stoneValueExceeded = false;

    [this.tags, this.allowedUsers, this.pawnAuctions] = await Promise.all([
      this.pawnTagCache.toPromise(),
      this.allowedUsersPromise(),
      this.pawnAuctionCache.systemAuctions().toPromise()
    ]);

    this.$scope.$watch('$ctrl.pawn', () => {
      if (!this.pawn) {
        return;
      }

      if (this.allowedUsers.length === 1) {
        this.pawn.appraisalUserId = this.allowedUsers[0].id;
      }
    })

    this.$scope.$watch('$ctrl.calculatedPawn', () => {
      if (!this.calculatedPawn) {
        this.maxAmount = null;
        return;
      }
      this.initMaxAppraisalAmount();
      const appraisedAmount = new BigNumber(this.calculatedPawn.appraisalAmount);
      this.maxAmount = appraisedAmount.add(this.maxAdditionalAmount).toNumber();

      if (this.calculatedPawn.auctionId != null) {
        let auction = _.find(this.pawnAuctions, {id: this.calculatedPawn.auctionId});
        if (auction != null && auction.conditionType === 'EXPIRATION_DATE_WITH_GRACE_PERIOD') {
          let momentDateFormat = 'YYYY-MM-DD';
          this.calculatedPawn.auctionedOnDate = moment(this.calculatedPawn.expirationDate, momentDateFormat)
            .add(this.pawnProduct.gracePeriod, 'days')
            .format(momentDateFormat);
        }
      }
    });
  }

  initMaxAppraisalAmount() {
    if (!this.pawnProduct.taggedInterestRates) {
      this.pawn.maxAppraisalAmount = this.calculatedPawn.appraisalAmount;
    }
  }

  /**
   * Due to automation only current user is allowed
   */
  async allowedUsersPromise() {
    const [allUsers, allRoles] = await Promise.all([this.userCache.toPromise(), this.rolesCache.toPromise()]);

    const users = allUsers.filter(u => !u.phantom)
      .filter(u => u.enabled)
      .map(u => this.attachPermissions(u, allRoles));

    const currentUser = users.find(u => u.id === this.authentication.context.realUserId);
    const currentBranchId = this.authentication.context.branchId;

    const isAppraiserInCurrentBranch = currentUser.branchId === currentBranchId ?
      _.find(currentUser.permissions, {name: 'PAWN_ITEM_APPRAISE'}) : false;
    const isAppraiserAdmin = _.find(currentUser.permissions, {name: 'PAWN_ITEM_APPRAISE_ADMIN'});

    if (!isAppraiserInCurrentBranch && !isAppraiserAdmin) {
      this.popup({
        header: 'Error',
        text: 'You are not allowed to appraise a pawn. Check your permissions and branch.'
      });
      throw `Your user (id=${this.authentication.context.realUserId}) has no either 'PAWN_ITEM_APPRAISE_ADMIN', or 'PAWN_ITEM_APPRAISE' permission while being in the wrong the branch.`
    }

    const appraisers = users.filter(u => _.find(u.permissions, {name: 'PAWN_ITEM_APPRAISE'}))
      .filter(u => u.branchId === currentBranchId);

    if (isAppraiserInCurrentBranch && !isAppraiserAdmin) {
      return [currentUser];
    }

    return appraisers;
  }

  attachPermissions(user, allRoles) {
    return {
      ...user,
      permissions: !_.isEmpty(user.roles) ? _.find(allRoles, {id: user.roles[0].id}).permissions : []
    }
  }

  isAppraiserDisabled() {
    return !this.allowedUsers || this.allowedUsers.length === 1;
  }

  async $onChanges() {
    if (_.isEmpty(this.pawnProduct) || _.isEmpty(this.branch)) {
      return;
    }

    this.maxAdditionalAmount = new BigNumber(this.pawnProduct.maxAdditionalAmount);
    this.supportedPawnTagIds = this.pawnProduct.supportedPawnTagIds
      .filter(tag => this.branch.supportedPawnTagIds.includes(tag));
  }

  updateMaxLowerInterestRate() {
    const intermediateResultWithDecimal = new BigNumber(this.pawn.maxAppraisalAmount)
      .div(100)
      .mul(this.pawnProduct.maxAmountLowerInterestRate)
      .div(50);
    this.calculatedMaxAmountLowerInterestRate = Math.floor(intermediateResultWithDecimal) * 50;
    this.lowerInterestRate = _.min(this.pawnProduct.taggedInterestRates.map(tir => tir.interestRate));
  }

  isLevel1QuotaExceeded() {
    if (!this.calculatedPawn || !this.tags) {
      return false;
    }

    const calculatedTag = this.tags.find(t => t.id === this.calculatedPawn.tagId);
    return (calculatedTag || {}).level > 1;
  }

  async fetchCalculation() {
    if (!this.calculationNeedsUpdate || this.calculationInProgress || !this.selectedItems || this.selectedItems.length === 0) {
      return;
    }

    this.calculationInProgress = true;

    this.newPawnService.roundDownToNearestHundreds(this.pawn);
    const request = this.newPawnService.createPawnRequest(this.pawnProduct, this.pawn, this.selectedItems);

    try {
      this.calculatedPawn = await this.newPawnService.calculatePawnPromise(request, this.pawnProduct);
      this.calculatedTagId = this.calculatedPawn.tagId;
    } catch (error) {
      this.calculatedPawn = null;
      this.calculatedTagId = null;
      this.popup({
        header: 'Error',
        text: error.errorMessage
      });
    }

    this.calculationInProgress = this.calculationNeedsUpdate = false;
  }
}

module.component('customerPawnCreateParameters', {
  templateUrl: templateUrl,
  bindings: {
    form: '=',
    branch: '<',
    pawnProduct: '<',
    pawn: '=',
    calculatedPawn: '=',
    selectedItems: '<',
    calculationNeedsUpdate: '='
  },
  controller: CustomerPawnCreateParameters
})
